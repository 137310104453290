import slugify from '@sindresorhus/slugify'
import { useOfferingStore } from '~/stores/offering'
import { useCourseStore } from '~/stores/course'
import { useCourseAreaStore } from '~/stores/courseArea'
import { useIntCourseStore } from '~/stores/intCourse'
import useHelpers from '~/composables/useHelpers'

type Offering = models.server.api.curriculum.offering.Offering
type Course = models.server.api.sitecore.course.Course

import capePageMap from '~/data/cape-page-map.json'

interface MappingMethods {
  [key: number]: (params: string[]) => Promise<string | undefined>
}

export default function () {
  const app = useNuxtApp()
  const { getProductUrl, stringEmpty } = useHelpers()

  const regexConditions = [
    '/(course|offering)/-/././(.*?)/', // course/offering pages ([1], 2)
    '/(course|offering)/(.*?)($|[?/])', // course/offering with id pages ([3], 4, 5)
    '/(courses)/(.*?)($|[?/])', // cape pages ([6], 7, 8)
    '/international/(course-details)/-/i/.*?/(.*?)/', // international course page ([9], 10)
    '/international/(package)/-/p/p/.*?/(.*?)/', // international pathway page ([11], 12)
    '/international/(course|course-details|package)/(.*?)($|[?/])', // international course/pathway page with id ([13], 14, 15)
    '/(search)[?]?(.*)', // search ([16], 17)
    '/international/(course-search-results|agents)[?]?(.*)', //international search ([18], 19)
    '/locations/(qr)/(.*)', // locations qr code redirect ([20], 21)
    '/media/Files/PDFs/(.*?)$' // pdf redirect ([22])
  ]

  const regex = new RegExp(regexConditions.join('|'), 'i')

  const mapping: MappingMethods = {
    1: getDomesticUrl,
    3: getDomesticUrl,
    6: getCapeUrl,
    9: getInternationalCourseUrl,
    11: getInternationalPackageUrl,
    13: getInternationalUrl,
    16: getSearchUrl,
    18: getSearchUrl,
    20: getLocationUrl,
    22: getPdfUrl
  }

  async function getDomesticUrl([type, id]: string[]) {
    // get offering if type is offering
    let offering: Offering | undefined = undefined
    if (type === 'offering') {
      const offeringId = id
      if (offeringId) {
        const offeringStore = useOfferingStore()
        offering = await app.runWithContext(() =>
          offeringStore.fetchOne(offeringId)
        )
      }
    }

    // always need the course
    let course: Course | undefined = undefined
    const courseId = offering ? offering.courseId : id
    if (courseId) {
      const courseStore = useCourseStore()
      course = await app.runWithContext(() => courseStore.fetchOne(courseId))
    }

    const redirectUrl = getProductUrl({ course, offering })

    return redirectUrl || undefined
  }

  async function getCapeUrl([_type, slug]: string[]) {
    let redirectUrl: string | undefined = undefined

    const key = slug as keyof typeof capePageMap
    const courseAreaSlug = capePageMap[key]?.slug

    if (courseAreaSlug) {
      const courseAreaStore = useCourseAreaStore()
      const courseArea = await app.runWithContext(() =>
        courseAreaStore.fetchBySlug(courseAreaSlug)
      )

      redirectUrl = getProductUrl({ courseArea })
    }

    return redirectUrl
  }

  async function getInternationalCourseUrl([_type, id]: string[]) {
    let redirectUrl: string | undefined = undefined

    if (id) {
      const courseIntStore = useIntCourseStore()
      const course = await app.runWithContext(() =>
        courseIntStore.fetchOneByCourseId(id)
      )
      const title = slugify(course?.title)
      redirectUrl = `/international/courses/${title}--${id}`
    }

    return redirectUrl || undefined
  }

  async function getInternationalPackageUrl([_type, id]: string[]) {
    let redirectUrl: string | undefined = undefined

    if (id) {
      const intCourseStore = useIntCourseStore()
      const pathway = await app.runWithContext(() =>
        intCourseStore.fetchOnePathwayById(id)
      )
      const name = slugify(pathway?.outcome)
      redirectUrl = `/international/package/${name}--${id}`
    }

    return redirectUrl || undefined
  }

  async function getInternationalUrl([type, id]: string[]) {
    let redirectUrl: string | undefined = undefined

    switch (type) {
      case 'course':
      case 'course-details':
        redirectUrl = await getInternationalCourseUrl([type, id])
        break

      case 'package':
        redirectUrl = await getInternationalPackageUrl([type, id])
        break
    }

    return redirectUrl || undefined
  }

  async function getSearchUrl([type, query]: string[]) {
    const params = new URLSearchParams(query)

    switch (type) {
      case 'search':
        // domestic search
        return `/course-search?keyword=${params.get('q') ?? ''}`
      case 'course-search-results':
        // international search
        return `/international/course-search?keyword=${params.get('k') ?? ''}`
      case 'agents':
        // international agent search
        return `/international/agent-search?keyword=${params.get('agency') ?? ''}`
    }
  }

  async function getLocationUrl([_type, slug]: string[]) {
    if (!slug) return undefined

    return `/locations/${slug}`
  }

  async function getPdfUrl([_type]: string[]) {
    if (!_type) return undefined
    const config = useRuntimeConfig().public
    const frontDoorApiUrl = config.frontDoorApiUrl
    return `${frontDoorApiUrl}/api/-/media/Files/PDFs/${_type}`
  }

  async function getRedirectUrl(from?: string | null) {
    if (!from) return undefined
    const match = removeTrailingSlash(from)?.match(regex)

    if (!match) return undefined

    const index = match.slice(1).findIndex(Boolean) + 1
    const params = match.slice(index).filter(Boolean)
    const redirectUrl = await mapping[index]?.(params)

    if (stringEmpty(redirectUrl))
      throw createError({
        statusCode: 404
      })

    return redirectUrl
  }

  function removeTrailingSlash(str?: string) {
    return str && str.replace(/\/+$/, '')
  }

  return { getRedirectUrl }
}
